import React, { useState, useEffect, useRef } from 'react';
import styles from './Assistant.module.css'; // Import the CSS module
import { marked } from 'marked'; // Import the marked library for Markdown to HTML conversion
import { v4 as uuidv4 } from 'uuid'; // Import UUID library for sessionId

const Assistant = () => {

  // Function to load HubSpot script only if it hasn't been loaded
  const loadHubspotScript = () => {
    if (!document.getElementById('hs-script-loader')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = '//js.hs-scripts.com/21342320.js';
      document.body.appendChild(script);
    }
  };

  useEffect(() => {
    // Call the function to load the HubSpot script
    loadHubspotScript();

    // Check the URL for a preloaded question query string
    const queryParams = new URLSearchParams(window.location.search);
    const preloadedQuestion = queryParams.get('question');

    if (preloadedQuestion) {
      sendMessage(preloadedQuestion); // Send the preloaded question
      
      // Update the page title
      document.title = `${preloadedQuestion} - Aida by FCCI™`;
  
      // Update the meta description
      let metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", `Get insights and answers about "${preloadedQuestion}" from Aida, your guide for Christian business leadership.`);
      } else {
        metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = `Get insights and answers about "${preloadedQuestion}" from Aida, your guide for Christian business leadership.`;
        document.head.appendChild(metaDescription);
      }
  
      // Use the history API to remove the query string from the URL without reloading the page
      const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.replaceState({ path: newUrl }, '', newUrl);
  
  } else {
      document.title = 'Aida by FCCI™'; // Default title if no query string
  
      // Set a default meta description if there's no query string
      let metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", "Aida by FCCI provides guidance on faith and business integration for Christian business leaders.");
      } else {
        metaDescription = document.createElement('meta');
        metaDescription.name = 'description';
        metaDescription.content = "Aida by FCCI provides guidance on faith and business integration for Christian business leaders.";
        document.head.appendChild(metaDescription);
      }
  }
  
  }, []); // Runs only once when the component is mounted

  // Generate sessionId when component is first mounted
  const [sessionId] = useState(uuidv4()); // Create a unique session ID using UUID

  // Track session metrics
  const [sessionStartTime, setSessionStartTime] = useState(Date.now());
  const [messageCount, setMessageCount] = useState(0);
  const [previousMessageTime, setPreviousMessageTime] = useState(null);
  const [timeBetweenMessages, setTimeBetweenMessages] = useState(0);
  
  const initialGreeting = {
    role: 'assistant',
    content: "Hi! I'm Aida. I can help you with questions about FCCI and Christian business leadership. Is there anything I can help answer for you?"
  };

  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);  // No initial message, greet separately
  const [isLoading, setIsLoading] = useState(false); // State to show loading spinner
  const [threadId, setThreadId] = useState(null);  // Store threadId for tracking conversation
  const [isQuestionSubmitted, setIsQuestionSubmitted] = useState(false); // Track when the user submits a question
  const [suggestedQuestion, setSuggestedQuestion] = useState(''); // Store the suggested question
  const [resources, setResources] = useState([]); // Store fetched resources

  const messagesEndRef = useRef(null);

  // Suggested questions array
  const suggestedQuestionsArray = [
    "Does Jesus care about my business?",
    "Why integrate faith and business?",
    "How does FCCI help business leaders?",
    "Why is stewardship an important concept?",
    "How do I find values-aligned team members?"
  ];

  // Pick a random question from the array
  const getRandomSuggestedQuestion = () => {
    const randomIndex = Math.floor(Math.random() * suggestedQuestionsArray.length);
    return suggestedQuestionsArray[randomIndex];
  };

  useEffect(() => {
    setSuggestedQuestion(getRandomSuggestedQuestion());
  }, []); // Only run on initial mount to randomize the question

  useEffect(() => {
    scrollToLatestMessage();
    updateLinksToOpenInNewTab(); // Make sure links open in a new tab after each render
  }, [messages]);

  // Scrolls to the latest message instead of all the way to the bottom
  const scrollToLatestMessage = () => {
    const messageElements = document.querySelectorAll(`.${styles.message}`);
    let targetMessage = null;
  
    // Loop through the messages and find the last assistant message before a resource message
    for (let i = messageElements.length - 1; i >= 0; i--) {
      const messageElement = messageElements[i];
      
      // If the message is an assistant message and NOT a resource message
      if (messageElement.classList.contains(styles.messageAssistant) && !messageElement.classList.contains(styles.resourceBackground)) {
        targetMessage = messageElement;
        break; // Stop once we find the right message
      }
    }
  
    // Scroll to the identified target message, or fallback to the last message
    if (targetMessage) {
      targetMessage.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    } else if (messageElements.length > 0) {
      // If no specific assistant message was found, scroll to the last message
      messageElements[messageElements.length - 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  };
  

  const updateLinksToOpenInNewTab = () => {
    const assistantMessages = document.querySelectorAll(`.${styles.messageAssistant} a`);
    assistantMessages.forEach((link) => {
      link.setAttribute('target', '_blank');
      link.setAttribute('rel', 'noopener noreferrer'); // For security reasons (prevents window hijacking)
    });
  };

  // Function to calculate time between messages
  const calculateTimeBetweenMessages = () => {
    const currentTime = Date.now();
    if (previousMessageTime) {
      const timeDiff = (currentTime - previousMessageTime) / 1000; // Convert to seconds
      setTimeBetweenMessages(timeDiff);
    }
    setPreviousMessageTime(currentTime);
  };

  const sendMessage = async (question, isSuggestedQuestion = false) => {
    const userInput = question || input;
    if (!userInput.trim()) return;
  
    setIsLoading(true); // Show spinner when sending request
    setMessageCount(prevCount => prevCount + 1); // Increment message count
    calculateTimeBetweenMessages(); // Track time between messages
  
    // Add user input to messages
    const updatedMessages = [...messages, { role: 'user', content: userInput }];
    
    // Add assistant's greeting as the first message when the user submits a question
    if (!isQuestionSubmitted) {
      updatedMessages.unshift(initialGreeting);
    }
  
    setMessages(updatedMessages);
    setInput('');
    setIsQuestionSubmitted(true); // Mark that a question has been submitted
  
    try {
      // Send request to start conversation or continue it with threadId
      const response = await fetch('https://us-central1-aida-b7a8d.cloudfunctions.net/api/ask-assistant', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ input: userInput, threadId }), // Pass threadId if it exists
      });
  
      const data = await response.json();
  
      const newThreadId = data.threadId || threadId;
      setThreadId(newThreadId);
  
      // Start streaming the assistant's response
      const eventSource = new EventSource(`https://us-central1-aida-b7a8d.cloudfunctions.net/api/stream-response?threadId=${newThreadId}`);
  
      let currentAssistantMessage = { role: 'assistant', content: '' };
      setMessages((prevMessages) => [...prevMessages, currentAssistantMessage]);
  
      eventSource.onmessage = function (event) {
        if (event.data === '[DONE]') {
          eventSource.close();
          
          // Convert the full assistant message to HTML using `marked` now that it's complete
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            updatedMessages[updatedMessages.length - 1] = {
              ...currentAssistantMessage,
              content: marked(currentAssistantMessage.content), // Convert to HTML after completion
            };
            return updatedMessages;
          });
  
          setIsLoading(false); // Hide spinner when response is received
          saveInteraction(userInput, currentAssistantMessage.content, isSuggestedQuestion); // Save the interaction to Firebase
  
          // Only fetch related resources if there is an assistant response
          if (currentAssistantMessage.content.trim() !== '') {
            fetchResourcesForKeywords(currentAssistantMessage.content,userInput); // Fetch related resources
          }
          return;
        }
      
        // Stream the content as chunks arrive, without `marked`
        const parsedData = JSON.parse(event.data);
        if (parsedData.content) {
          const deltaContent = parsedData.content;
          
          // Append the content chunk to the current assistant message
          currentAssistantMessage.content += deltaContent;
      
          // Update the message state to display the raw text incrementally
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            // Update only the last message (the assistant's message) with the new chunk
            updatedMessages[updatedMessages.length - 1] = {
              ...currentAssistantMessage,
              content: currentAssistantMessage.content, // Just plain text until completed
            };
            return updatedMessages;
          });
        }
      };
  
      eventSource.onerror = function (error) {
        console.error('Error in event stream:', error);
        eventSource.close();
        setIsLoading(false); // Hide spinner if there is an error
      };
  
    } catch (error) {
      console.error('Error sending message:', error);
      setIsLoading(false); // Hide spinner if there's an error
    }
  
    // Rotate the suggested question after the user clicks it
    if (isSuggestedQuestion) {
      setSuggestedQuestion(getRandomSuggestedQuestion());
    }
  };
  
 // Function to fetch related resources from the backend
const fetchResourcesForKeywords = async (assistantResponse, userQuery) => {
  try {
    const response = await fetch(`https://us-central1-aida-b7a8d.cloudfunctions.net/api/fetch-resource?assistantResponse=${assistantResponse}&userQuery=${userQuery}`);

    if (!response.ok) {
      console.error(`Error fetching resources: ${response.statusText}`);
      setResources([]); // Clear resources if there's an error
      return;
    }

    const resources = await response.json();

    // If resources are found, add the static message only if not already added
    if (resources && resources.length > 0) {
      setMessages((prevMessages) => {
        const lastMessage = prevMessages[prevMessages.length - 1];

        // Check if the message mentioning resources was already added
        if (lastMessage.content !== "You might be interested in:") {
          return [
            ...prevMessages,
            {
              role: 'assistant',
              content: "You might be interested in:" // Static message to notify the user about resources
            }
          ];
        }

        return prevMessages; // If the message is already present, don't add it again
      });

      // Append the resources as a new assistant message, but don't duplicate content
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          role: 'assistant',
          content: '', // Content remains empty for resources, they're displayed via resource key
          resources: resources.map((resource) => ({
            title: resource.title,
            description: resource.description,
            url: resource.url,
            thumbnail: resource.thumbnail,
            type: resource.type,
          })),
        }
      ]);

      // Set resources state for transcript download or display purposes
      setResources(resources);
      console.log('Resources fetched successfully:', resources);
    } else {
      setResources([]); // Clear resources if no matches are found
      console.log('No resources found');
    }
  } catch (error) {
    console.error('Error fetching resources:', error.message);
    setResources([]); // Clear resources on error
  }
};

  
  // Function to save the interaction to Firebase along with session metrics
  const saveInteraction = async (userMessage, assistantMessage, isSuggestedQuestion) => {
    const sessionEndTime = Date.now();
    const sessionDuration = (sessionEndTime - sessionStartTime) / 1000; // Session duration in seconds
    const userAgent = navigator.userAgent;

    try {
      const response = await fetch('https://us-central1-aida-b7a8d.cloudfunctions.net/api/save-interaction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userMessage,
          assistantMessage,
          isSuggestedQuestion, // Store whether it was a suggested question
          sessionDuration, // Session duration in seconds
          messageCount, // Number of messages sent
          timeBetweenMessages, // Time between the most recent messages
          userAgent, // User-agent information
          sessionId, // Save the session ID
        }),
      });

      if (!response.ok) {
        // If response is not ok, throw error with status
        const errorText = await response.text();
        throw new Error(`Error from server: ${response.status} - ${errorText}`);
      }

      console.log('Interaction saved successfully');
    } catch (error) {
      console.error('Error saving interaction to Firebase:', error.message);
    }
  };

// Function to convert HTML to RTF
const convertHtmlToRtf = (content) => {
  return content
    .replace(/<b>(.*?)<\/b>/g, '\\b $1\\b0') // Bold
    .replace(/<i>(.*?)<\/i>/g, '\\i $1\\i0') // Italic
    .replace(/<ul>|<\/ul>/g, '') // Remove <ul> tags
    .replace(/<li>(.*?)<\/li>/g, '\\bullet $1\\par') // List items
    .replace(/<ol>|<\/ol>/g, '') // Remove <ol> tags
    .replace(/<p>(.*?)<\/p>/g, '$1\\par') // Paragraph
    .replace(/<a href="(.*?)">(.*?)<\/a>/g, '$2 (\\ul $1\\ul0)') // Hyperlink with underlined URL
    .replace(/<br\s*\/?>/g, '\\par') // Line breaks
    .replace(/&nbsp;/g, ' ') // Non-breaking spaces
    .replace(/&amp;/g, '&') // Ampersand
    .replace(/&lt;/g, '<') // Less than
    .replace(/&gt;/g, '>') // Greater than
    .replace(/&#39;/g, '\'') // Apostrophe
    .replace(/&quot;/g, '"'); // Quotes
};

const downloadTranscript = () => {
  // Define the RTF header with properly escaped backslashes
  let rtfContent = `{\\rtf1\\ansi\\ansicpg1252\\deff0
    {\\fonttbl{\\f0 Arial;}}
    {\\colortbl;\\red0\\green0\\blue0;}
    \\f0\\fs24
  `;

  // Add the messages to the RTF file, converting HTML tags to RTF formatting
  messages.forEach((msg) => {
    const userRole = msg.role === 'user' ? 'You: ' : 'Aida: ';
    let content = msg.content || ''; // Ensure content is defined

    // Replace HTML entities and tags with RTF equivalents
    content = convertHtmlToRtf(content);

    // Append the user role and message content
    rtfContent += `\\b ${userRole}\\b0 ${content}\\par `;
  });

  // Append the resources at the end of the transcript
  if (resources.length > 0) {
    rtfContent += '\\par \\b Featured Resources: \\b0\\par';
    resources.forEach((resource) => {
      rtfContent += `
        \\b Title: \\b0 ${resource.title}\\par
        \\b Description: \\b0 ${resource.description}\\par
        \\b URL: \\b0 ${resource.url}\\par
      `;
    });
  }

  // Close the RTF file
  rtfContent += '}';

  // Create the Blob and download the RTF file
  const blob = new Blob([rtfContent], { type: 'application/rtf' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'chat_transcript.rtf';
  link.click();
  URL.revokeObjectURL(url); // Clean up the URL after download
};


  return (
    <div className={styles.container}>
      {/* Top icon and title section */}
      <div className={styles.headerContainer}>
        <div className={styles.iconContainer}>
          <span className="material-icons" style={{ fontSize: '71px', color: '#FFFFFF' }}>
            face_4
          </span>
        </div>
        <h1 className={styles.title}>Aida by FCCI™ - Christian Business Leadership AI Assistant</h1>
      </div>

      {/* Assistant greeting (visible by default, hidden after first question) */}
      {!isQuestionSubmitted && (
        <div className={styles.greetingContainer}>
          <span className="material-icons" style={{ fontSize: '20px', color: '#FFFFFF', marginRight: '5px' }}>
            face_4
          </span>
          Hi! I'm Aida. I can help you with questions about Fellowship of Companies for Christ International (FCCI) and Christian business leadership in general. Is there anything I can help answer for you?
        </div>
      )}

      {/* Chat messages (visible only after user submits a question) */}
      {isQuestionSubmitted && (
        <div className={styles.messagesContainer}>
          {messages.map((message, index) => (
            <div
              key={index}
              className={`${styles.message} ${message.role === 'user' ? styles.messageUser : styles.messageAssistant} ${message.resources ? styles.resourceBackground : ''}`} // Conditionally add background color for resource messages
            >
              <div className={styles.messageContent}>
                {message.role === 'assistant' && (
                  <span className="material-icons" style={{ fontSize: '20px', color: '#FFFFFF', marginRight: '5px' }}>
                    face_4
                  </span>
                )}
                <strong>{message.role === 'user' ? 'You: ' : ''}</strong>
                <span dangerouslySetInnerHTML={{ __html: message.content }} /> {/* Safely render the HTML content */}
              </div>

              {/* Conditionally display resource cards */}
              {message.resources && (
                <div className={styles.resourcesContainer}>
                  {message.resources.map((resource, resourceIndex) => (
                    <div key={resourceIndex} className={styles.resourceCard}>
                      {resource.thumbnail && (
                        <a href={resource.url} target="_blank" rel="noopener noreferrer">
                        <img
                          src={resource.thumbnail}
                          alt={resource.title}
                          className={styles.resourceThumbnail}
                        />
                        </a>
                      )}
                      <h4>{resource.title}</h4>
                      <p>{resource.description}</p>
                      <a href={resource.url} target="_blank" rel="noopener noreferrer">View {resource.type}</a>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}

          {/* Display bouncing dots inside assistant's message bubble when waiting */}
          {isLoading && (
            <div className={`${styles.messageAssistant} ${styles.loadingDotsMessage}`}>
              <div className={styles.bouncingDots}>
                <div></div>
                <div></div>
                <div></div>
              </div> {/* Three bouncing dots */}
            </div>
          )}

          <div ref={messagesEndRef} />
        </div>
      )}

      {/* Input field */}
      <div className={styles.inputContainer}>
        <textarea
          className={styles.textAreaInput}
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="What's on your mind?"
          rows={1}
          style={{ height: 'auto' }} // Ensure height is reset to auto before recalculating
          onInput={(e) => {
            e.target.style.height = 'auto'; // Reset the height to auto to properly calculate scrollHeight
            e.target.style.height = `${e.target.scrollHeight}px`; // Set height based on the content's scrollHeight
          }}
          onKeyDown={(e) => {
            if (!isLoading && e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              sendMessage();
            } else if (isLoading && e.key === 'Enter') {
              e.preventDefault(); // Suppress Enter key if waiting for a response
            }
          }}
        />

        <button onClick={() => sendMessage()} disabled={isLoading} className={styles.sendButton}>
          <span className="material-icons" style={{ fontSize: '30px', color: '#89A9BB', marginRight: '5px' }}>send</span>
        </button>
      </div>

      {/* Suggested Question */}
      {!isLoading && (
        <div className={styles.suggestedQuestion}>
          <p>Try:</p>
          <a href="#"
            className={styles.suggestedQuestionLink} 
            onClick={(e) => {
              e.preventDefault(); // Prevent default anchor link behavior
              sendMessage(suggestedQuestion, true); // Send the suggested question and flag it
            }}>
            {suggestedQuestion}
          </a>
        </div>
      )}

      {/* Conditionally render the Download Transcript button */}
      {isQuestionSubmitted && (
        <div className={styles.downloadContainer}>
          <button onClick={downloadTranscript} className={styles.downloadButton}>
            <span className="material-icons" style={{fontSize: '30px', color: '#89A9BB', marginRight: '5px' }}>
              download
            </span>
            Download Transcript
          </button>
        </div>
      )}

      {/* Footer */}
      <footer className={styles.footer}>
        <p>Aida can make mistakes. Please verify important information.<br />
        ©2024 Fellowship of Companies for Christ International - <a href="https://fcci.org">fcci.org</a><br /> v1.0.0</p>
      </footer>
    </div>
  );
};

export default Assistant;
